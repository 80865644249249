import { Link } from 'react-router-dom'
import React, {useContext, useEffect, useState} from 'react'
import MUIDataTable from 'mui-datatables'
import api from '../../services/api'
import { DataTableOptions } from '../../components/dataTableOptions'
import {MainContext} from "../../context/MainContext";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router";
import Swal from "sweetalert2";

export default function ListingWeapons() {
  const navigate = useNavigate();
  const {setPageTitle, setLoading} = useContext(MainContext)
  const { authUser } = useContext(AuthContext)

  const [active, setActive] = useState<boolean>(true);
  const [weapon, setWeapon] = useState<any>([])

  const getWeapons = async () => {
    setLoading(true)
    await api.post('/api/weapons/my', {active}).then((response) => {
      setWeapon(response.data)
    })
    setLoading(false)
  }

  useEffect(() => {
    getWeapons()
  }, [active])

  useEffect(() => {
    setLoading(true)
    setPageTitle("Meu Acervo")

    if (!authUser?.athlete?.cr_number || !authUser?.athlete?.cr_expedition_date || !authUser?.athlete?.cr_validity_date){
      setLoading(false)
      Swal.fire({
        title: 'Atenção!',
        text: "Informações do CR devem ser informadas no seu perfil!",
        icon: 'warning',
      }).then(()=> {
        return navigate("/profile");
      })
    }

    const fetchData = async () => {
      await getWeapons()
    };

    fetchData().then(setLoading(false))
  }, [])

  const columns: any = [
    {
      name: 'description', label: 'Modelo', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'active', label: 'Ativa', options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (index: string | number) => {
          const statusColor = weapon[index]?.active ? 'success' : 'danger';
          const statusText = weapon[index]?.active?'Ativa':'Inativa';
          return (
              <span className={`badge rounded-pill bg-${statusColor}-subtle border-${statusColor}-subtle text-${statusColor}-emphasis`}>
                  &nbsp;{statusText}&nbsp;
              </span>
        )},
      },
    },
    {
      name: 'sigma_number', label: 'Nº sigma', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'serial_number', label: 'Nº serie', options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: '', label: '', options: {
        filter: false,
        false: true,
        customBodyRenderLite: (index: string | number) => {return (
              <Link className='btn btn-sm btn-warning' to={`/weapons/update/${weapon[index]?.id}`} title="Editar"><i className="bi bi-pencil" aria-hidden="true"></i> </Link>
          )
        },
      },
    },
  ]

  const options: any = DataTableOptions

  return (
    <>
      <div className='mb-4'>
        <Link className='btn btn-success mb-3' to='/weapons/create'><i
            className="bi bi-plus-circle">&nbsp;</i> Cadastrar Arma</Link>

        <div className="col-md-12 mb-2">
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" id="active"
                   onChange={evento => setActive(evento.target.checked)}
                   checked={active}/>
            <label className="form-check-label" htmlFor="active">{`Mostrando armas ${active? ' ativas' : ' inativas'}`} </label>
          </div>
        </div>

        <MUIDataTable
            title={''}
            data={weapon}
            columns={columns}
            options={options}
        />
      </div>

    </>
  )
}
