import React, {useContext, useEffect, useState} from 'react';
import {Document, Page, Text, View, StyleSheet, pdf} from '@react-pdf/renderer';
import {MainContext} from "../../context/MainContext";
import api from "../../services/api";

const styles = StyleSheet.create({
    page: {
        // fontFamily:'Arial',
        paddingBottom: 40,
        paddingTop: 19,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 8,
        flexDirection: 'column',
        position: 'relative',
        // transform: 'scale(0.8)'
    },
    section: {
        marginBottom: 20,
    },
    table: {
        margin: 'auto',
        width: '100%',
        borderWidth: 0.5,
        borderColor: '#000',
        borderCollapse: 'collapse',
    },
    tableRow: {
        flexDirection: 'row',},
    tableCell: {
        padding: 2.5,
        borderWidth: 0.5,
        borderColor: '#000',
        borderCollapse: 'collapse',
        flexGrow: 1,
    },
    signatureLine: {
        marginTop: 30,
        textAlign: 'center',
        fontSize: 8,
        marginBottom: 15,
        lineHeight: 1.4
    },
    footer: {
        position: 'absolute',
        bottom: 15,
        left: 20,
        right: 20,
        textAlign: 'center',
        fontSize: 10,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
        color: 'grey',
    }
});

const HabitualitytPrinting:  React.FC<any> = ({ athleteId, arrHabitualities, childToParent}:any) => {
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [athlete, setAthlete] = useState<any>([])
    const [habitualities, setHabituality] = useState<any>([])

    const {club, setLoading} = useContext(MainContext)

    async function getAthlete() {
        await api.get(`/api/athletes/${athleteId}`).then((response) => {
            const data = response.data
            setAthlete(data)
        })
    }

    async function getHabituality() {
        let arrayHabitualities:any = []

        arrayHabitualities.push({type: "allowed" , habitualities: arrHabitualities.filter((habituality: { weapon: { restricted: number } }) =>
                habituality?.weapon?.restricted == 0
            ).sort((a:any, b:any) => a.weapon?.caliber_id - b.weapon?.caliber_id)});

        arrayHabitualities.push({type: "restricted" , habitualities: arrHabitualities.filter((habituality: { weapon: { restricted: number } }) =>
                habituality?.weapon?.restricted == 1
            ).sort((a:any, b:any) => a.weapon?.caliber_id - b.weapon?.caliber_id)});

        // ordenr de acordo como for pedido no form

        setHabituality(arrayHabitualities)
    }

    const handleOpenPDF = async () => {
        if (dataLoaded){
            const blob = await pdf(<HabitualityPDF />).toBlob();
            const url = URL.createObjectURL(blob);
            window.open(url);
        }
    };

    useEffect(() => {
        setLoading(true)
        const loadData = async () => {
            await new Promise(async (resolve) => {
                resolve([
                    await getAthlete(),
                    await getHabituality(),
                ]);
            });

            setDataLoaded(true);
        };
        loadData().then(r => setLoading(false));
    }, [club]);

    useEffect(() => {
        if (dataLoaded) {
            handleOpenPDF().then(r => {
                if (childToParent) {
                    childToParent('imprimiu');
                }
            });
        }
    }, [dataLoaded]);

    const HabitualityPDF = () => (
        <Document>
            <Page style={styles.page} size={"A4"} >
                <View style={styles.section}>
                    <View style={{ textAlign: 'center' }}>
                        <Text style={{fontWeight: 'bold' ,marginTop:3,marginBottom:8 }}>ANEXO E</Text>
                        <Text style={{fontWeight: 'bold' ,marginTop:3,marginBottom:8 }}>COMPROVAÇÃO DE PARTICIPAÇÕES EM TREINAMENTOS E/OU COMPETIÇÕES DE TIRO (HABITUALIDADE)</Text>
                        <Text style={{marginTop:3,marginBottom:8 }}>(art. 35 do Decreto nº 11.615/2023)</Text>
                    </View>

                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text style={{ fontWeight: 'bold',marginTop:3,marginBottom:8  }}>Dados da Entidade de Tiro</Text>

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>NOME</Text>
                            <Text style={[styles.tableCell, { width: '44%', textAlign:"center"}]}>{club?.fantasia}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>CNPJ: {club?.cnpj}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>CERTIFICADO DE REGISTRO</Text>
                            <Text style={[styles.tableCell, { width: '44%', textAlign:"center"}]}>{club?.cr_number}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>DATA VAL: {club?.cr_validity_date ? new Date(club?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10) : ''}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>ENDEREÇO</Text>
                            <Text style={[styles.tableCell, { width: '44%' }]}>{club?.address?.street}, {club?.address?.number}, {club?.address?.district}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{club?.address?.city?.name} - {club?.address?.city?.state?.abbreviation}</Text>
                        </View>
                    </View>

                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text style={{ fontWeight: 'bold',marginTop:3,marginBottom:8  }}>Dados do Atirador Desportivo</Text>

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>NOME</Text>
                            <Text style={[styles.tableCell, { width: '44%', textAlign:"center" }]}>{athlete?.full_name}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>CPF: {athlete?.cpf}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>
                                CERTIF. DE REGISTRO
                            </Text>
                            <Text style={[styles.tableCell, { width: '44%', textAlign:"center" }]}>
                                {athlete?.cr_number}
                            </Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>
                                DATA VAL: {athlete?.cr_validity_date ? new Date(athlete?.cr_validity_date).toLocaleString('pt-BR').substr(0, 10) : ''}
                            </Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>ENDEREÇO</Text>
                            <Text style={[styles.tableCell, { width: '44%' }]}>{athlete?.address?.street}, {athlete?.address?.number}, {athlete?.address?.district}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{athlete?.address?.city?.name} - {athlete?.address?.city?.state?.abbreviation}</Text>
                        </View>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '23%' }]}>DADOS DA FILIAÇÃO</Text>
                            <Text style={[styles.tableCell, { width: '44%' }]}>NÚMERO: {athlete?.member_number}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>DATA: {athlete?.membership_date ? new Date(athlete?.membership_date).toLocaleString('pt-BR').substr(0, 10) : ''}</Text>
                        </View>
                    </View>

                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text style={{ fontWeight: 'bold',marginTop:3,marginBottom:8 }}>Dados da Habitualidade</Text>

                    {habitualities.map((habitualities:any, index:number) =>
                        <React.Fragment key={index}>
                            <View style={[styles.table]}>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, { width: '57%' }]}>Calibre de uso {habitualities?.type == "allowed" ? "permitido" : "restrito" }</Text>
                                    <Text style={[styles.tableCell, { width: '43%' }]}>Tipo de Evento</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell,{width: '6%', textAlign:"center"}]}>Ordem</Text>
                                    <Text style={[styles.tableCell,{width: '16%', textAlign:"center"}]}>Data-hora</Text>
                                    <Text style={[styles.tableCell,{width: '10%', textAlign:"center"}]}>Sigma</Text>
                                    <Text style={[styles.tableCell,{width: '15%', textAlign:"center"}]}>Calibre</Text>
                                    <Text style={[styles.tableCell,{width: '10%', textAlign:"center"}]}>Qtd Munição</Text>
                                    <Text style={[styles.tableCell,{width: '43%', textAlign:"center"}]}>Treinamento ou Competição</Text>
                                </View>

                                {habitualities?.habitualities.map((habituality: any, index: number) =>
                                    <View style={[styles.tableRow]} >
                                        <Text style={[styles.tableCell,{width: '6%', textAlign:"center"}]}>{index + 1}</Text>
                                        <Text style={[styles.tableCell,{width: '16%'}]}>{new Date(habituality?.release_date).toLocaleString('pt-BR')}</Text>
                                        <Text style={[styles.tableCell,{width: '10%', textAlign:"justify"}]}>{habituality?.weapon?.sigma_number}</Text>
                                        <Text style={[styles.tableCell,{width: '15%'}]}>{habituality?.weapon?.caliber?.description}</Text>
                                        <Text style={[styles.tableCell,{width: '10%', textAlign:"center"}]}>{habituality?.quantity_shots}</Text>
                                        <Text style={[styles.tableCell,{width: '43%'}]}> {habituality?.event_type?.description}</Text>
                                    </View>
                                )}

                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, { width: '100%' }]}>Registros da</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCell, { width: '33%' }]}>Livro/Sistema</Text>
                                    <Text style={[styles.tableCell, { width: '33%' }]}>Folha/Nr Registro</Text>
                                    <Text style={styles.tableCell}>Data do Lançamento:</Text>
                                </View>
                            </View>
                            <Text>&nbsp;</Text>
                            <Text>&nbsp;</Text>
                        </React.Fragment>
                    )}

                    <Text>&nbsp;</Text>
                    <View style={styles.signatureLine}>
                        <Text>__________________________</Text>
                        <Text>{club?.president_name}</Text>
                        <Text>CPF: {club?.president_cpf}</Text>
                        <Text>Presidente do Clube de Tiro Caça e Pesca Tapejara – RS</Text>
                    </View>
                    <View style={styles.signatureLine}>
                        <Text>____________________________</Text>
                        <Text>De acordo: {athlete?.full_name}</Text>
                        <Text>CPF: {athlete?.cpf}</Text>
                    </View>
                </View>
                <Text fixed style={styles.footer} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}/>
            </Page>
        </Document>
    );

    return null
};

export default HabitualitytPrinting;
