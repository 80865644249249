import React, {useContext, useEffect, useRef, useState} from "react";
import Select from "react-select";
import Async from "react-select/async";
import api from "../../services/api";
import Swal from "sweetalert2";

import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {AuthContext} from "../../context/AuthContext";
import {MainContext} from "../../context/MainContext";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Link, useNavigate} from "react-router-dom";
import HabitualitytPrinting from "./HabitualitytPrinting";

export default function FormHabituality() {
    const navigate = useNavigate();
    const {setPageTitle, setLoading} = useContext(MainContext)
    const { authUser } = useContext(AuthContext)
    const [print, setPrint] = useState<any>({})

    const childToParent = (childdata:any) => {
        setPrint({imprimir:false, athlete:null})
    }

    const handleFocus = () => {
        if (quantityShotsRef.current) {
            quantityShotsRef.current.select();
        }
    };

    const formHabitualityRef = useRef<HTMLDivElement | null>(null);
    const quantityShotsRef = useRef<HTMLInputElement>(null);
    const inputSearch = useRef<any>(null);
    const inputEvent = useRef<any>(null);

    const INITIAL_DATA = {
        value: 0,
        label: 'Selecione o Sócio',
    };

    const [habitualityId, setHabitualityId] = useState('' as any);
    const [arrayEventTypes, setArrayEventTypes] = useState([] as any)
    const [arrayWeapons, setArrayWeapons] = useState([] as any)

    const [habitualities, setHabitualities] = useState<any>([])
    const [editId, setEditId] = useState('-1' as any);

    const [weapon, setWeapon] = useState<number>(0);
    const [athlete, setAthlete] = useState(INITIAL_DATA);
    const [releaseDate, setReleaseDate] = useState(dayjs() as any);
    const [quantityShots, setQuantityShots] = useState('' as any);
    const [eventType, setEventType] = useState<number>(1);

    const [startDate, setStartDate] = useState(null as any);
    const [endDate, setEndDate] = useState(null as any);
    const [orderReport, setOrderReport] = useState(null as any);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const dispatchHabitualityObject:any = {
        "athlete_id": athlete.value,
        "weapon_id": weapon,
        "event_type_id": eventType,
        "quantity_shots": quantityShots,
        "release_date": dayjs(releaseDate).subtract(3, 'hour'),
    }

    const validateHabitualityRegister = () => {
        if (athlete.value == 0) {
            throw "Sócio deve ser informado";
        }

        if (!releaseDate) {
            throw "Data de lançamento deve ser informada";
        }

        if (weapon === 0) {
            throw "Arma deve ser informada";
        }

        if (!eventType) {
            throw "Evento deve ser informado";
        }

        if (!quantityShots) {
            throw "Quantidade de tiros deve ser informada";
        }

        if (quantityShots <= 0) {
            throw "Quantidade de tiros deve ser maior que zero";
        }
    }

    useEffect(() => {
        setLoading(true)
        setPageTitle("Habitualidades")

        try {
            if (authUser?.role !== 'athlete'){
                setStartDate(dayjs().add(-1, 'year'))
                setEndDate(dayjs())
            }

            const fetchData = async () => {
                await eventTypes()

                if (authUser?.role === 'athlete'){
                    await getHabitualities()
                }
            };

            fetchData()
        }catch (e) {
            console.log(e)
        }finally {
            setLoading(false)
            if (authUser?.role !== 'athlete' && inputSearch.current) {
                inputSearch.current.focus();
            }
        }
    },  [])

    useEffect(() => {
        if (athlete.value){
            getHabitualities(athlete.value)
            setSelectedItems([]);
        }
    }, [startDate, endDate])

    async function finishHabituality() {
        Swal.fire({
            title: 'Tem certeza que deseja finalizar?',
            text: "Caso tenha deixado um lançamento pendente sera perdido!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#198754",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
            cancelButtonText: "Voltar"
        }).then((result) => {
            if (result.isConfirmed) {
                resetForm()
                resetDatesHabitulity()
                setAthlete(INITIAL_DATA)
                if (inputSearch.current) {
                    inputSearch.current.focus();
                }
            }
        });
    }

    async function resetForm(resetDate = true) {
        setSelectedItems([]);
        setEditId("-1")

        if (resetDate){
            setReleaseDate(dayjs())
        } else {
            setReleaseDate(dayjs(releaseDate.second(dayjs().second())))
        }
        setHabitualityId("")
        setEventType(1)
        setQuantityShots("0")
        setWeapon(0)

        if (inputEvent.current) {
            inputEvent.current.focus();
        }
    }

    async function resetDatesHabitulity() {
        setStartDate(dayjs().add(-1, 'year'))
        setEndDate(dayjs())
    }

    function changeHabituality(hab:any, index :any) {
        setSelectedItems([]);
        setEditId(index)

        setHabitualityId(hab.id)
        setWeapon(hab.weapon_id)
        setReleaseDate(dayjs(hab.release_date))
        setEventType(hab.event_type_id)
        setQuantityShots(hab.quantity_shots)

        if (formHabitualityRef.current) {
            const y = formHabitualityRef.current.getBoundingClientRect().top + window.pageYOffset + (-80);
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    async function eventTypes() {
        const response = await api.get(`/api/eventTypes`)
        const result = await response
        return setArrayEventTypes(
            await result.data.map((leve:any) => ({
                label: leve.description ,
                value: leve.id
            })),
        )
    }

    async function socios(value = '') {
        const response = await api.get(`/api/athletes/getToSelect/?search=${value}`)
        const result = await response
        return await result.data.map((athlete:any) => ({
            label: `${athlete.member_number || ""} - ${(['coordinator', 'admin'].includes(authUser?.role) ? athlete.full_name : athlete.full_name_abv)}`,
            validities: athlete.cr_validity_date,
            value: athlete.id
        }))
    }

    async function armas(id = '') {
        const response = await api.post(`/api/weapons/my/${id}`, {'active':true})
        const result = await response

        return setArrayWeapons(
            await result.data.map((weapon:any) => ({
                label: weapon?.weapon_category?.description + " - " + weapon?.description + " - Cal. " + weapon?.caliber?.description + " - " + weapon?.serial_number,
                validities: weapon?.craf_validity,
                value: weapon.id
            })),
        )
    }

    const getHabitualities = async (id: number = 0) => {
        setLoading(true)
        try {
            await api.post(`/api/habitualities/my/${id}`,{startDate: dayjs(startDate).format('YYYY-MM-DD')  , endDate: dayjs(endDate).format('YYYY-MM-DD') }).then(async (response) => {
                setHabitualities(response.data)
            })
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();
            validateHabitualityRegister()

            setLoading(true)
            if (habitualityId == "") {
                api.post('/api/habitualities', dispatchHabitualityObject).then((result) => {
                  Swal.fire({
                    title: 'Sucesso!',
                    text: result.data.message,
                    icon: 'success',
                    timer: 5000,
                      didClose: () => {
                          resetForm(false)
                          getHabitualities(athlete.value)
                      },
                  })
                }).then(setLoading(false))
                  .catch(erro => {
                      setLoading(false)
                      Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.message,
                        icon: 'error',
                      })
                    }
                  );
            } else {
                api.put('/api/habitualities/' + habitualityId, dispatchHabitualityObject).then((result) => {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        didClose: () => {
                            getHabitualities(athlete.value)
                            resetForm()
                        },
                    })
                }).then(setLoading(false))
                    .catch(erro  => {
                        setLoading(false)
                        Swal.fire({
                                title: 'Erro!',
                                text: erro.response.data.message,
                                icon: 'error',
                            })
                        }
                    )
            }
        }catch (err: any) {
            setLoading(false)
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    const handleCheckAll = () => {
        const allItemIds = habitualities.map((item: { "": any; }) => item);
        setSelectedItems(allItemIds);
    };

    const handleUncheckAll = () => {
        setSelectedItems([]);
    };

    const toggleItemSelection = (item: any) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.some((selectedItem) => selectedItem.id === item.id)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    async function validateCpf(data:any) {
        setLoading(false)
        if (['coordinator', 'admin'].includes(authUser?.role)){
            return true
        }

            const result = await Swal.fire({
                title: "Confirme seu CPF para prosseguir!",
                text: "Insira seu CPF com ou sem pontuação.",
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                    autocomplete: "off",
                },
                showCancelButton: true,
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#198754",
                cancelButtonColor: "#d33",
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: async (input) => {
                    try {
                        const response = await api.post('/api/athletes/verify', { athlete_id: data.value, identifier: input });
                        if (response?.data == 1) {
                            return;
                        } else {
                            throw 'CPF inválido';
                        }
                    } catch (error:any) {
                        Swal.showValidationMessage(` ${error.message || error} `);
                    }
                },
                didClose: () => {
                    if (inputEvent.current) {
                        inputEvent.current.focus();
                    }
                }
            });

            return result.isConfirmed;
    }

    async function setSocios(data:any) {
        try {
            if (dayjs(data.validities).diff(dayjs(), 'day') < 0) {
                await Swal.fire({
                    title: 'Atenção!',
                    text: "Atirador com CR Vencido!",
                    icon: 'warning',
                    allowOutsideClick: false,
                    didClose: () => {
                        if (inputEvent.current) {
                            inputEvent.current.focus();
                        }
                    }
                })
            }

            setLoading(true)
            const confirmed = await validateCpf(data);
            if (confirmed) {
                setAthlete(data);
                await resetForm()
                await resetDatesHabitulity()
                setLoading(false)
                await getHabitualities(data.value)

                await armas(data.value)

                if (inputEvent.current) {
                    inputEvent.current.focus();
                }
            }

        }catch (err: any) {
            setLoading(false)
            await Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className="col-lg-12">

                {authUser?.role !== 'athlete' &&
                    <form onSubmit={resgister}  autoComplete='off' className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Sócio</h5>

                            <div className="col-md-6">
                                <Async
                                    name="search"
                                    autoFocus={false}
                                    ref={inputSearch}
                                    isLoading={false}
                                    cacheOptions
                                    loadOptions={socios}
                                    noOptionsMessage={() => "Nenhum registro encontrado"}
                                    onChange={(data: any) => {
                                        setSocios(data).then(setLoading(false))
                                    }}
                                    value={athlete}
                                    defaultOptions
                                />
                            </div>

                        </div>
                    </div>

                    {athlete.value != 0 && (
                        <div ref={formHabitualityRef} className="card">
                        <div className="card-body">
                            <h5 className="card-title">Lançar habitualidade</h5>
                            <div className="row g-3">

                                <div className="col-md-4">
                                    <label htmlFor="release_date" className="form-label">Data de lançamento<i className="text-danger"> *</i></label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <DesktopDateTimePicker  className={"form-control sadate"}
                                                         value={releaseDate}
                                                         onChange={evento => setReleaseDate(evento)}/>
                                    </LocalizationProvider>

                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="event_type" className="form-label">Evento <i className="text-danger"> *</i></label>
                                    <Select
                                        ref={inputEvent}
                                        options={arrayEventTypes}
                                        name='event_type_id'
                                        id='event_type_id'
                                        onChange={(data: any) => {
                                            setEventType(data.value)
                                        }}
                                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                                        value={arrayEventTypes.find((option: { value: any; }) => option.value == eventType) || ''}
                                        placeholder='Tipo de evento'
                                    />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="quantity_shots" className="form-label">Quantidade de tiros <i
                                        className="text-danger"> *</i></label>
                                    <input type="number" className="form-control" id="quantity_shots" placeholder="0"
                                           ref={quantityShotsRef}
                                           onFocus={handleFocus}
                                           onChange={evento => setQuantityShots(evento.target.value)} value={quantityShots}/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="weapon_id" className="form-label">Arma <i className="text-danger"> *</i></label>
                                    <div className="input-group">
                                        <Select
                                            options={arrayWeapons}
                                            className={"col"}
                                            name='weapon_id'
                                            id='weapon_id'
                                            onChange={(data: any) => {
                                                if (dayjs(data.validities).diff(dayjs(releaseDate), 'day') < 0) {
                                                    setWeapon(0)
                                                    Swal.fire({
                                                        title: 'Atenção!',
                                                        text: "Arma com CRAF Vencido!",
                                                        icon: 'warning',
                                                    }).then(()=>{
                                                        if(['coordinator', 'admin'].includes(authUser?.role)){
                                                            Swal.fire({
                                                                title: 'Gostaria de editar alguma informação?',
                                                                icon: "warning",
                                                                showCancelButton: true,
                                                                confirmButtonColor: "#198754",
                                                                cancelButtonColor: "#d33",
                                                                confirmButtonText: "Sim",
                                                                cancelButtonText: "Não"
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    return navigate(`/weapons/update/${data?.value}`)
                                                                }
                                                            });
                                                        }
                                                    })
                                                } else {
                                                    setWeapon(data.value)
                                                }
                                            }}
                                            noOptionsMessage={() => 'Nenhum registro encontrado'}
                                            value={arrayWeapons.find((option: {
                                                value: any;
                                            }) => option.value == weapon) || 0}
                                            placeholder='Escolha a arma'
                                        />
                                        {(['coordinator', 'admin'].includes(authUser?.role) && weapon != 0) &&
                                            <Link className='input-group-text btn btn-primary' to={`/weapons/update/${weapon}`}
                                                  title="Editar"><i className="bi bi-pencil" aria-hidden="true"></i> </Link>
                                        }
                                    </div>
                                </div>

                                <div className="d-flex">
                                    <button type="submit" className="btn btn-success me-2"><i className="bi bi-floppy2">&nbsp; </i>Gravar</button>
                                    {(habitualityId !== '' && authUser?.role !== 'athlete') &&
                                        <button type="button" onClick={() => resetForm()} className="btn btn-danger me-2"><i className="bi bi-x-circle-fill">&nbsp;</i> Cancelar</button>
                                    }

                                    <button type="button" onClick={() => finishHabituality()} className="btn btn-primary  ms-auto"><i className="bi bi-clipboard2-check-fill">&nbsp; </i>Finalizar</button>
                                </div>

                        </div>
                    </div>
                </div>
                    )}
                </form>
                }

                {(authUser?.role === 'athlete' || athlete.value !== 0) && (
                    <>
                        <div className="card row">
                            <div className="card-body">
                                <h5 className="card-title">Habitualidade</h5>
                                {(!['organizer', 'athlete'].includes(authUser?.role)) && (
                                    <div className="col-md-12 mb-3">
                                        <div className="row g-3">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                                <div className="col-md-3">
                                                    <label htmlFor="birthDate" className="form-label">Data inicial <i
                                                        className="text-danger"> *</i></label>
                                                    <DatePicker className={"form-control sadate"}
                                                                value={startDate}
                                                                onChange={evento => setStartDate(evento)}/>
                                                </div>

                                                <div className="col-md-3">
                                                    <label htmlFor="birthDate" className="form-label">Data final <i
                                                        className="text-danger"> *</i></label>
                                                    <DatePicker className={"form-control sadate"}
                                                                value={endDate}
                                                                onChange={evento => setEndDate(evento)}/>
                                                </div>
                                            </LocalizationProvider>

                                            {/*<div className="col-md-2">*/}
                                            {/*    <label htmlFor="order" className="form-label">Ordernar por</label>*/}
                                            {/*    <Select*/}
                                            {/*        defaultValue={{label: 'Data', value: 'realese_date'}}*/}
                                            {/*        options={[{label: 'Data', value: 'realese_date'},*/}
                                            {/*            {label: 'Calibre', value: 'caliber_id'}*/}
                                            {/*        ]}*/}
                                            {/*        value={[{label: 'Data', value: 'realese_date'},*/}
                                            {/*            {label: 'Calibre', value: 'caliber_id'}*/}
                                            {/*        ].find(option => option.value == orderReport)}*/}
                                            {/*        name='order'*/}
                                            {/*        id='order'*/}
                                            {/*        onChange={(data: any) => {*/}
                                            {/*            setOrderReport(data.value)*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</div>*/}

                                            {selectedItems.length !== 0 && (
                                            <div className="col-md-3">
                                                <br/>
                                                <button type={"button"} className="btn btn-primary mt-2"
                                                        onClick={() => setPrint({
                                                            imprimir: true,
                                                            athlete: athlete.value
                                                        })}>
                                                    <i className="bi bi-printer-fill"></i> Imprimir
                                                </button>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-12">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col" align={"center"}>
                                                {((!['organizer', 'athlete'].includes(authUser?.role)) && !(habitualities == undefined || habitualities.length <= 0)) && (
                                                    <>
                                                        {selectedItems.length !== habitualities.length && (
                                                            <button className={'btn btn-sm btn-primary'} title={"Marcar Todos"}
                                                                onClick={handleCheckAll}><i className="bi bi-plus"></i>
                                                        </button>
                                                    )}

                                                    {selectedItems.length === habitualities.length && (
                                                        <button className={'btn btn-sm btn-primary'}
                                                                title={"Desmarcar Todos"} onClick={handleUncheckAll}><i
                                                            className="bi bi-dash-lg"></i></button>
                                                    )}
                                                </>
                                                )}
                                            </th>
                                            <th scope="col">Data de lançamento</th>
                                            <th scope="col"></th>
                                            <th scope="col">Arma</th>
                                            <th scope="col">Quantidade de tiros</th>
                                            <th scope="col">Evento</th>
                                            <th scope="col"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(habitualities != undefined && habitualities.length > 0)? (
                                            <>
                                                    {habitualities.map((res: any, index: number) =>
                                                        <tr key={`${index}`}
                                                            className={`${index == editId ? 'table-primary' : ''}`}>
                                                            <td align={"center"}>
                                                                {(!['organizer', 'athlete'].includes(authUser?.role)) && (
                                                                    <label>
                                                                        <input
                                                                            id={`marcada-${index}`}
                                                                            name={`marcada-${index}`}
                                                                            type="checkbox"
                                                                            checked={selectedItems.some((selectedItem) => selectedItem.id === res.id)}
                                                                            onChange={() => toggleItemSelection(res)}
                                                                        />
                                                                    </label>
                                                                )}
                                                            </td>
                                                            <th scope='row'>{new Date(res?.release_date).toLocaleString('pt-BR')}</th>
                                                            <td>
                                                                <span
                                                                    className={`badge rounded-pill ${!res?.weapon?.restricted ? 'bg-success' : 'bg-danger'}`}>{!res?.weapon?.restricted ? 'Permitida' : 'Restrita'}</span>
                                                            </td>
                                                            <td>{res?.weapon?.description} - Cal. {res?.weapon?.caliber?.description} -  {res?.weapon?.serial_number}</td>
                                                            <td>{res.quantity_shots}</td>
                                                            <td>{arrayEventTypes.find((option: {
                                                                value: any;
                                                            }) => option.value == res.event_type_id)?.label}</td>
                                                            <td>
                                                                {((authUser?.role === 'organizer' && index < 1) || (['coordinator', 'admin'].includes(authUser?.role))) && (
                                                                    <button type="button"
                                                                            className="btn btn-sm btn-warning"
                                                                            title="Editar"
                                                                            onClick={() => changeHabituality(res, index)}>
                                                                        <i
                                                                            className="bi bi-pencil"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={7} className='text-center'>
                                                        Nenhuma habitualidade lançada
                                                    </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>

                        {print.imprimir &&
                            <HabitualitytPrinting athleteId={print.athlete} arrHabitualities={selectedItems} childToParent={childToParent}/>
                        }
                    </>
                )}
            </div>
        </>
    );
}

